/*(function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id))
        return;
    js = d.createElement(s);
    js.id = id;
    js.src = 'https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v2.12&appId=888245171338347&autoLogAppEvents=1';
    fjs.parentNode.insertBefore(js, fjs);
}(document, 'script', 'facebook-jssdk'))*/
//var lba = document.getElementsByClassName("social-button")

function myPopup(url) {
    //console.log(url);
    //event.preventDefault();
    window.open(url, 'mywin',
            'left=20,top=20,width=500,height=500,toolbar=1,resizable=0');
    return false;
}

/*for (var i = 0; i < lba.length; i++) {
    lba[i].addEventListener("click", myPopup, false);
}*/